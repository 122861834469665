<template>
  <the-container>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <template v-else>
      <div class="m2-6 my-md-6">
        <h3 class="ma-0">{{ projectPointData?.name }}</h3>
        <div
          v-if="projectPointData?.description"
          class="py-2 my-2"
          style="font-size: 18px"
          v-html="projectPointData?.description"
        />
      </div>
      <div v-if="projectPointData?.files?.length" class="mb-12 border-block pb-6">
        <div class="font-weight-bold my-6">Скачать пакет документов:</div>
        <div v-for="file in projectPointData?.files" :key="file.fileId">
          <v-row
            class="pa-0 ma-0 justify-space-between align-center cursor-pointer"
            @click="() => downloadFile(file)"
          >
            <v-col class="pa-md-0 pa-0 ma-0" cols="12" md="8" lg="8">
              <v-icon color="grey" class="d-inline">{{ getFileIcon(file?.name) }}</v-icon>
              <span class="ml-2 font-weight-medium text-caption">{{ file?.name }}</span>
            </v-col>
            <v-col cols="12" md="4" lg="4" class="pa-0 ma-0">
              <v-row class="ma-0 pa-0 align-center justify-end w-100">
                <div>
                  <v-btn class="text-caption text-blue-darken-4 ml-2" variant="text">
                    <v-icon color="blue" size="xs"> mdi-download </v-icon>
                    <span class="ml-2 text-body-2 title">Скачать</span>
                  </v-btn>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
      <ProjectForm
        v-if="isEditedForm"
        :is-vote="isVoteShowForm"
        :point-data="projectPointData"
        @accept="getProjectPoint"
      />
      <TaskPreview
        v-else-if="projectPointData"
        :point-data="projectPointData"
        @update="getProjectPoint"
      />
    </template>
  </the-container>
</template>
<script setup>
import TheContainer from '@/components/TheContainer'
import { api } from '@/api/Api'
import { useRoute } from 'vue-router/dist/vue-router'
import { computed, onMounted, ref } from 'vue'
import TaskPreview from '@/components/Tasks/TaskPreview'
import { POINT_TYPE_EXPERT, POINTS_STATUSES } from '@/constants/points'
import ProjectForm from '@/components/Project/ProjectForm.vue'
import { downloadFileWithLinkData, getFileIcon } from '@/helpers'

const route = useRoute()

const projectPointData = ref(null)
const isLoading = ref(false)

const isVoteShowForm = computed(() => projectPointData.value?.type === POINT_TYPE_EXPERT)
const isEditedForm = computed(
  () =>
    projectPointData.value?.status?.code1C === POINTS_STATUSES.NEW ||
    projectPointData.value?.status?.code1C === POINTS_STATUSES.FILLING ||
    projectPointData.value?.status?.code1C === POINTS_STATUSES.NEEDS_IMPROVEMENTS ||
    projectPointData.value?.status?.code1C === POINTS_STATUSES.SUSPENDED,
)

async function getProjectPoint() {
  isLoading.value = true
  try {
    await api
      .projects()
      .getPointById(route.params.id)
      .then((data) => {
        projectPointData.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function downloadFile(file) {
  downloadFileWithLinkData(file, file.name)
}

onMounted(() => {
  getProjectPoint()
})
</script>
